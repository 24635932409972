import AddToCartParametersInterface from './model/vo/event/add-to-cart-parameters-interface';
import CheckoutParametersInterface from './model/vo/event/checkout-parameters-interface';
import ClickEventParametersInterface from './model/vo/event/click-event-parameters-interface';
import EventParametersInterface from './model/vo/event/event-parameters-interface';
import GoogleAnalytics from './model/service/google/google-analytics';
import GoogleConfigurationInterface from './model/vo/google/google-configuration-interface';
import OrderParametersInterface from './model/vo/event/order-parameters-interface';
import PageViewParametersInterface from './model/vo/event/page-view-parameters-interface';
import ServiceInterface from './model/service/service-interface';
import SubmitEventParametersInterface from './model/vo/event/submit-event-parameters-interface';
import ItemViewParametersInterface from './model/vo/event/item-view-parameters-interface';

export default class VpEvents
{
    /**
     * The services that events should track on
     */

    private services: ServiceInterface[] = [];

    /**
     * Return the string to define all of the services
     *
     * @returns string
     */

    public define(): void
    {
        for (const service of this.services)
        {
            service.define();
        }
    }

    public trackEvent(event: string, parameters: EventParametersInterface): void
    {
        for (const service of this.services)
        {
            service.trackEvent(event, parameters);
        }
    }

    public trackPageView(parameters: PageViewParametersInterface): void
    {
        for (const service of this.services)
        {
            service.trackPageView(parameters);
        }
    }

    public trackClick(parameters: ClickEventParametersInterface): void
    {
        for (const service of this.services)
        {
            service.trackClick(parameters);
        }
    }

    public trackSubmission(parameters: SubmitEventParametersInterface): void
    {
        for (const service of this.services)
        {
            service.trackSubmission(parameters);
        }
    }

    public trackAddToCart(parameters: AddToCartParametersInterface): void
    {
        for (const service of this.services)
        {
            service.trackAddToCart(parameters);
        }
    }

    public trackCheckout(parameters: CheckoutParametersInterface): void
    {
        for (const service of this.services)
        {
            service.trackCheckout(parameters);
        }
    }

    public trackOrder(order: OrderParametersInterface): void
    {
        for (const service of this.services)
        {
            service.trackOrder(order);
        }
    }

    public trackItemView(parameters: ItemViewParametersInterface): void
    {
        for (const service of this.services)
        {
            service.trackItemView(parameters);
        }
    }

    /**
     * Add a service to track events on
     * @param service ServiceInterface
     */

    private addService(service: ServiceInterface): void
    {
        this.services.push(service);
    }

    /**
     * Add the Google Analytics service
     *
     * @param configuration GoogleConfigurationInterface
     */

    public addGoogleAnalytics(configuration: GoogleConfigurationInterface): void
    {
        const googleAnalytics = new GoogleAnalytics(configuration);

        this.addService(googleAnalytics);
    }
}