import VpEvents from './vp-events';

declare global
{
    interface Window
    {
        vpEvents: VpEvents;
    }
}

window.vpEvents = new VpEvents();