import ServiceConfigurationInterface from '../vo/service-configuration-interface';

export default abstract class AbstractService
{
    protected configuration!: ServiceConfigurationInterface;

    public defined = false;

    public constructor(configuration: ServiceConfigurationInterface)
    {
        this.configuration = configuration;
    }

    protected addScript(src: string, async: boolean): void
    {
        const tag = document.createElement('script');

        tag.type = 'text/javascript';
        tag.src = src;

        if (async)
        {
            tag.async = true;
        }

        document.head.appendChild(tag);
    }

    protected setDefined(): void
    {
        this.defined = true;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected filterParameters(params: {[key: string]: any}): {[key: string]: any}
    {
        for (const key in params)
        {
            if (params[key] === undefined)
            {
                delete params[key];
            }
        }

        return params;
    }
}